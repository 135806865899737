<template>
    <div style="text-align: left;">
        <div class="top">
            <img src="@/assets/images/clubCard/top_bg.png" alt="" class="bgimg">
            <div class="topcontent newBodyWidth df-jc-s-b df-ai-c m-t-10">
                <div class="df-ai-c">
                    <img src="@/assets/images/logo_f.png" class="pointer" @click="$oucy.go('/')" alt="">
                    <h4 class="title m-l-25">会员中心</h4>
                </div>
                <div class="df">
                    <div class="topItem" @click="$oucy.go('/acc/MemberCard?select=2')">购卡记录</div>
                    <div class="topItem" @click="$oucy.go('/acc/MemberCard?select=1')">我的会员</div>
                </div>
            </div>
        </div>
        <div class="center">
            <div class="newBodyWidth">
                <div class="tabs text-center df-ai-c df-jc-c">
                    <div class="tab" :class="select==0?'select':''" @click="select=0">家具VIP卡</div>
                    <div class="tab" :class="select==1?'select':''" @click="select=1">物流VIP卡</div>
                    <div class="tab" :class="select==2?'select':''" @click="select=2">设计师VIP卡</div>
                </div>
                <div class="cards df-jc-c" v-if="prlductData[select]">
                    <div class="card" v-for="(v,i) of prlductData[select].list" :key="i" :class="'card_'+select">
                        <div>
                        <div class="cardTop p-10" :style="{'backgroundImage':'url('+$oucy.ossUrl+v.memberCardLevelImage+')'}">
                            <div class="f20 bold name">{{prlductData[select].memberCardTypeTitle}}⌈{{v.memberCardLevelName}}⌋ <span class="level" :style="{color:prlductData[select].memberCardFontColor}">￥{{v.memberCardLevelPrice}}</span></div>
                            <div class="f12 m-t-10 remark">享铜卡会员专属权益，会员折扣等；有效期为{{prlductData[select].memberCardTypeDays}}天！</div>
                        </div>
                            
                        <div class="cardCenter">
                            <div v-for="(vv,ii) of v.ticketBasicForNums" :key="ii">
                                <div class="df df-ai-c m-t-20">
                                    <img :src="$oucy.ossUrl+vv.memberCardTicketIcon" alt="" class="icon m-l-40">
                                    <div class="m-l-20">
                                        <div>{{vv.memberCardTicketName}}</div>
                                        <div class="m-t-10">{{vv.memberCardTicketRemark}}x{{vv.cardTicketAmount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="cardTottom">
                            <div class="levelPrice m-b-20">- 总价值￥{{v.levelPrice}}(券) -</div>
                            <div class="btn" @click="openDialog(v,i)">立即开通</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <img src="@/assets/images/clubCard/bottom.png" alt="" class="bgimg">
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <div>
                <div class="df-jc-s-b">
                    <div>支付金额</div>
                    <div>￥{{item.memberCardLevelPrice}}</div>
                </div>
                <div class="p-20 coupons">
                    <div v-for="(v,i) of couponList" class="coupon" :key="i">
                        <coupon2 :selectId="coupon.id" @clickCoupon="clickCoupon" :v="v"></coupon2>
                    </div>
                </div>
                <div class="df-jc-s-b">
                    <div>优惠券</div>
                    <div v-if="coupon.memberCardUserTicketFee">-{{coupon.memberCardUserTicketFee}}</div>
                    <div v-else>请选择优惠券</div>
                </div>
                <div class="df-jc-s-b m-t-10">
                    <div>需支付费用</div>
                    <div v-if="coupon.memberCardUserTicketFee">￥{{item.memberCardLevelPrice-coupon.memberCardUserTicketFee}}</div>
                    <div v-else>￥{{item.memberCardLevelPrice}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="gotoPay(),dialogVisible = false">去支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { ticket, financial } from "@/service"
export default {
    name: "clubCard",
    components: {},
    data() {
        return {
            select: 0,
            title: null,
            selectId: null,
            prlductData: [],
            userInfo: localGet('loginUser') || {},
            dialogVisible: false,
            item:{},
            couponList:[],
            coupon:{},
            ticketId:null
        }
    },
    mounted() {
        this.getMemberCardTypeList()

    },
    methods: {

        getMemberCardTypeList() {
            financial.getMemberCardTypeList({}).then(res => {
                this.prlductData = res
            })
        },
        openDialog(v,i){
            this.title=v.memberCardTypeBasic.memberCardTypeTitle+'⌈'+v.memberCardLevelName+'⌋'
            this.item=v
            this.coupon={}
            this.ordermembercardQueryEffectTicket(this.item)

        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        gotoPay(){
            localSet('payObject',{
                payPlatform:'browser',
                payMode: 0,
                ticketId: this.coupon.id,
                fee: this.item.memberCardLevelPrice,
                cardLevelId:this.item.id,
                type:'createMemberCardOrder',
            })
            this.$oucy.go('/payCenter/pay')
        },
        ordermembercardQueryEffectTicket(v){
            financial.ordermembercardQueryEffectTicket({cardLevelId:v.id}).then(res=>{
                if(res){
                    this.couponList=res
                    this.dialogVisible=true
                }else{
                    this.gotoPay()
                }
            })
        },
        clickCoupon(v){
            console.log(v)
            this.coupon=v
        },

    }
}
</script>
<style scoped lang="less">
.top {

    position: relative;

    .topcontent {
        /*background: #0f0;*/
        position: absolute;
        transform: translateX(-50%);
        top: 0;
        left: 50%;
        width: ;
    }
}

.bgimg {
    width: 100%;
    min-width: 1200px;
}

.title {
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #F2DAAF;
}

.card {
    background: linear-gradient(180deg, #FFFFFF 0%, #FFF9EC 100%);
    box-shadow: 0px 0px 18px 1px rgba(135, 78, 27, 0.16862745098039217);
    border-radius: 8px 8px 8px 8px;
    margin: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardTop {
    background-size: 360px 170px;
    background-position: center center;
    border-radius: 8px;
}

.topItem {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 25px;
    margin-right: 5px;
    cursor: pointer;
}

.center {
    background: #FFFAF3;
    padding-bottom: 160px;
}

.tab {
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #767676;
    margin: 0 33px;
    padding: 10px;
    cursor: pointer;

    &.select {
        font-weight: bold;
        color: #AF8247;
        position: relative;

        &:before {
            content: ' ';
            width: 24px;
            height: 5px;
            border-radius: 17px;
            display: inline-block;
            ;
            position: absolute;
            transform: translateX(-50%);
            bottom: 0;
            left: 50%;
            background: #AF8247;
        }
    }
}

.icon {
    width: 80px;
    height: 80px;
}

.level {
    font-size: 18px;
    background: #FFFAF0;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 17px;
}

.levelPrice {
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 30px;
}

.cardCenter {
    padding-right: 15px;
}

.cardTottom {
    margin-bottom: 38px;
}

.btn {
    background: #333;
    text-align: center;
    padding: 9px;
    width: 80%;
    margin: 0 auto;
    border-radius: 20px;
    cursor: pointer;
}

.card_0 {
    .name {
        color: #8D6B3E;
    }

    .remark {
        color: #fff;
    }

    .btn {
        background: #DEBE87;
        color: #fff;
    }
}

.card_1 {
    .name {
        color: #fff;
    }

    .remark {
        color: #C5C5C5;
    }

    .btn {
        background: #323232;
        color: #fff;
    }
}

.card_2 {
    .name {
        color: #576579;
    }

    .remark {
        color: #B3B8BE;
    }

    .btn {
        background: #D9E1EA;
        color: #576579;
    }
}
.coupons{
    max-height: 300px;
    overflow: auto;
    margin: 10px 0;
}
.coupon+.coupon{
    margin-top: 20px;
}
</style>